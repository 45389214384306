.car-drop-down {
    display: flex;
    position: relative;
    img {
        width: 50px;
        height: 50px;
    }    
    .car-drop-down-selected-item {
        margin-left: 10px;
        cursor: pointer;
        p {
            margin: 0;
        }
        div {
            display: flex;
            svg {
                margin-left: 10px;
            }
        }
        
    }
    .car-drop-down-list {
        position: absolute;
        top: 50px;
        left: 50px;
        background-color: #fff;
        min-width: 200px;
        padding: 10px;
        border: 1px solid #ccc;
        box-shadow: 2px 2px 2px #ccc;
        z-index: 10;
        p {
            cursor: pointer;
            margin: 0;
            padding: 5px;
            border-bottom: 1px solid #ccc;
            &:last-child {
                border-bottom: unset;
            }
        }
        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
}
