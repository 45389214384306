.sidebar {
    width: 20%;
    background-color: $sidebar;
    color:#fff;
    height: auto;
    min-height: 100vh;
    padding: 0 18px;
    transition: all .5s;
    @media(max-width: 992px) {
        display: none;
        transition: all 1s;
    }
    ul {
        margin-top: 20px;
        padding-left: 0;
        li {
            list-style: none;
            font-size: $small-text;
            a {
                text-decoration: none;
                color: $menu-title;
                svg {
                    width: 25px !important;
                }
                &:hover {
                    color: $white;
                }
            }
        }
        .menu-title {
            font-size: $large-text;
            color: $menu-title;
            border-bottom: 0.5px solid $menu-title;
            margin-top: 20px;
            line-height: 28px;
            svg {
                width: 25px !important;
            }
        }
    }
    // @media screen and (min-width: 1440px) {
    //     padding: 0 25px;
    // }
}

.sidebar-menu-button {
    position: absolute;
    left: 19%;
    top: 75px;
    background: #da1a25;
    color: #fff;
    padding: 4px 9px;
    border-radius: 50%;
    transition: all .5s;
    cursor: pointer;
    @media(max-width: 992px) {
        display: block;
        left: 10%;
        top: 30px;
    }
}

.active {
    color: #fff !important;
}