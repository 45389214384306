//colors
$sidebar: #272c33;
$red-color: #dc3545;
$menu-title: #9496a1;
$white: #ffffff;

//text-size
$small-text: 14px;
$medium-text: 16px;
$large-text: 18px;
$text-20: 20px;

//text-bold
$bold-text: 700;
$bolder-text: bolder;

//button-color
$danger: #d9534f;
$success: #5cb85c;

.text-20 {
    font-size: $text-20;
}
.text-bold-500 {
    font-weight: 500;
}