.login {
    // background-image: url('./../../images//bg.jpg');
    width: 100%;
    height: 100vh;
    // background-size: cover;
    background-color: $sidebar;
    display: flex;
    justify-content: center;
    align-items: center;
    form {
        width: 500px;
        background: #ccc;
        padding: 15px;
        display: flex;
        flex-direction: column;
        img {
            display: flex;
            align-self: center;
        }
        .remember-forgot-container {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .checkbox-container {
                display: flex;
                align-items: center;
                width: 25%;
                justify-content: space-around;
                margin-bottom: 0 !important;
                input {
                    width: 15px;
                    border: unset;
                }
                label {
                    margin-bottom: 0;
                }
                @media(max-width: 767px) {
                    width: 40%;
                }
            }
            a {
                text-decoration: none;
                &:hover {
                    color: $red-color;
                    transition: all 0.5s;
                }
            }
        }
    }
}

.reset-token-label,
.reset-email-label {
    background: #9b9b9b;
    padding: .375rem .75rem;
    border-radius: .25rem;
    margin-top: 10px;
}