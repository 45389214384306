.add-form {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.row {
    display: flex;
    flex-direction: column;
}
