@import './variables.scss';
@import './app.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

* {
    font-family: 'Roboto', sans-serif !important;
}

:root {
    scrollbar-width: thin !important;
}

body {
    padding: 0;
    margin: 0;
    position: relative;
}

#root {
    background-color: #f1f2f7;
}

.container-app {
    display: flex;
    width: 100%;
}

.page {
    padding: 15px 20px;
}

.delete-modal-content {
    background: $sidebar;
    position: fixed;
    top: 30%;
    left: 42%;
    z-index: 5;
    width: 30%;
    padding: 30px;
    border-radius: 10px;
    h3 {
        color: #fff;
    }
    @media(min-width: 767px) and (max-width: 992px) {
        left: 11%;
        width: 80%;
    }
    @media(max-width: 767px) {
        left: 11%;
        width: 80%;
    }
}

// body::-webkit-scrollbar {
//     width: 0;
// }

// body::-webkit-scrollbar-thumb {
//     background-color: #d9534f;
//     outline: 1px solid slategrey;
// }


.table-sm td {
    padding-top: 20px !important;
}

.table {
    margin-bottom: 10px !important;
}

.list-of-vehicles, 
.list-of-reports,
.list-of-users {
    padding: 0 20px;
    tr {
        cursor: pointer;
    }
}
.row {
    flex-direction: row;
}

#formBasicCheckbox {
    width: 15px;
    height: 15px;
}

// asterisk icon
svg.svg-inline--fa.fa-asterisk.fa-w-16 {
    width: 10px;
}

.btn.btn-danger {
    background-color: #DA1A25;
}

.img-container {
    display: flex;
    justify-content: center;
    background-color: #DA1A25;
    margin-bottom: 10px;
    // .img-anime {
    //     position: relative;
    //     animation-name: example;
    //     animation-duration: 5s;
    //     animation-timing-function: linear;
    //     animation-iteration-count: infinite;
    //     animation-direction: alternate;
    // }
    
    // @keyframes example {
    //     0%   {right:0px;}
    //     50%  {right:79%;}
    //     100% {right:0px;}
    //   }
}


.row {
    @media(max-width: 1140px) {
        flex-direction: column;
        .col-sm-4 {
            flex: 1;
            max-width: 100%;
            margin-bottom: 20px;
        }
        .col-sm-8 {
            flex: 1;
            max-width: 100%;
        }
    }
    @media(max-width: 992px) {
        .col-md-3,
        .col-sm-2 {
            max-width: 100%;
        }
    }
}

.list-of-vehicles,
.list-of-users {
    .search-by-text {
        position: relative;
        margin-top: -20px;
        svg {
            right: 10px;
            position: absolute;
            top: 35px;
        }
    }
}