.react-datepicker-wrapper {
    width: 100%;
    padding-bottom: 20px !important;
    .date-picker-btn {
        border: 1px solid #ced4da;
        border-radius: .25rem;
        padding: 6px 10px;
        background: #fff;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: #495057;
    }
}

.picker-width-20 {
    width: 20%;
    @media(max-width: 992px) {
        width: 100%;
    }
}

.picker-width-100 {
    width: 100%;
}