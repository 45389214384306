.customer-vehicle {
    display: flex; 
    justify-content: space-between; 
    align-items: flex-end;
    .customer-vehicle-select {
        display: flex; 
        justify-content: space-between; 
        align-items: flex-end;
         width: 70%;
         @media (max-width: '992px') {
            width: 100%;
        }
    }
    .customer-vehicle-btns {
        @media (max-width: '992px') {
            width: 20%;
        }
    }

    @media (max-width: '992px') {
        flex-wrap: wrap;
    }
  
}
.add-customer-vehicle {
    width: 20%; 
    margin: 10px 0 20px 0;
    @media (max-width: '992px') {
        width: 50%;
    }
}