.profile-banner-container {
    margin-bottom: 20px;
    .profile-banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            border-radius: 50%;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
        }
    }
}