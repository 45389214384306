.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 1.25rem !important;
    .icon-and-title {
        display: flex;
        align-items: center;
        svg {
            margin-right: 5px;
            margin-top: 1px;
        }
        .card-title {
            margin-bottom: 0;
        }
    }
}

.logout {
    color: #9496a1;
    margin-left: 5px;
    cursor: pointer;
    span {
        margin-left: 5px;
    }
}
.pagination {
    display: flex;
    justify-content: center;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    span {
        margin: 0 3px;
        cursor: pointer;
    }
    .activePage {
        display: flex;
        color: $red-color;
        border: 1px solid $red-color;
        padding: 0 5px;
    }
}

.common-button {
    width: 100%;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    color: #495057 !important;
}

.card {
    color: #495057 !important;
}

.select-search {
    margin-bottom: 15px;
    .select-search-input {
        position: relative;
        input {
            width: 100%;
            border: none;
            outline: none;
            border: 1px solid #ccc;
            padding: 5px 10px;
            border-radius: 5px;
        }
        svg {
            position: absolute;
            right: 4px;
            top: 12px;
            font-size: 10px;
        }
    }
    .select-search-list {
        padding: 10px;
        border: 1px solid #ccc;
        height: 300px;
        overflow: auto;
        div {
            margin-bottom: 5px;
        }
    }
}

.basic-info-box {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-tlfecz-indicatorContainer {
    svg {
        width: 15px !important;
        height: 15px !important;
        position: absolute !important;
        color: #434141 !important;
        right: 1px !important;
        top: 11px !important;
    }
}

.css-9gakcf-option {
    background-color: #fff !important;
    color: #434141 !important;
    cursor: pointer !important;
    padding: 0 12px !important;
}

.css-yt9ioa-option {
    padding: 0 12px !important;
}

.css-1wa3eu0-placeholder {
    color: hsl(0deg 0% 30%) !important;
}

.css-26l3qy-menu {
    margin-top: 0 !important;
    border-radius: 0 !important;
}

.files-upload-container {
    margin: 20px;
}

.files-upload-input {
    font-size: 25px;
    position: absolute !important;
    left: 0;
    top: 0;
    opacity: 0;
    width: 20% !important;
    cursor: pointer;
  }

  .files-upload-input::-webkit-file-upload-button {
      cursor: pointer;
  }
  
  .files-upload-label {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 4px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;
  }

  .media-preview {
      width: 250px;
      border: 2px solid #ccc;
      border-radius: 5px;
      padding: 5px;
      margin: 20px;
      img {
          cursor: pointer;
          width: 100%;  
          height: 200px;
          object-fit: cover;
      }
      div {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
      }
  }

  .media-files {
      display: flex;
      flex-wrap: wrap;
  }

  .no-media-files {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color: #ccc;
  }

  .no-filters {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #ccc;
  }

  .searchWrapper {
      padding: 3px 5px !important;
      .chip {
          padding: 1px 5px !important;
          border-radius: 3px !important;
      }
  }
  .multiSelectContainer ul {
    border: 1px solid #495057 !important;
    border-radius: 0 !important;
    box-shadow: 2px 2px 2px #ccc;
  }
  .multiSelectContainer li {
      padding: 0 10px !important;
  }

  .multiSelectContainer input {
    width: auto;
    min-width: 50%;
  }

  .display-none {
      display: none;
  }

  .customer-vehicles-list {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }


  .customer-vehicle-btns { 
      width: 10%;
      margin-bottom: 1rem;
  }

  .hidden {
      visibility: hidden;
  }

  .disabledVehicle {
      opacity: 0.5;
      pointer-events: none;
  }

  .media-and-pdf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

    @media (max-width: '767px') {
        .common-button {
            width: 100% !important;
            margin-bottom: 10px;
        }
      }


      .delete-service-logo {
        position: absolute;
        right: 0;
        top: 0;
        width: 13%;
       
    }
    @media (max-width: '767px') {
        .delete-service-logo {
            width: 35% !important;
        }
      }

    .service-logo-upload-input {
        width: 65% !important;
        @media (max-width: '767px') {
            .service-logo-upload-input {
                width: 85% !important;
            }
          }
    }