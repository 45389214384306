.right-panel{
    width: 80%;
    transition: all .5s;
    @media(max-width: 992px) {
        width: 100%;
    }
    .header {
        background: #fff;
        box-shadow: 0px 1px 1px rgb(0 0 0 / 15%);
        padding: 15px 20px;
        width: 100%;
        min-height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-search-container {
            position: relative;
            width: 60%;
            .search-by-text {
                width: 100%;
                position: relative;
                @media(max-width: 992px) {
                    width: 100%;
                }
                svg {
                    position: absolute;
                    top: 34.5px;
                    right: 10px;
                    color: #ccc;
                }
            }
            .header-dropdown {
                position: absolute;
                top: 65px;
                height: 500px;
                width: 100%;
                background: #fff;
                border: 1px solid #ccc;
                z-index: 11;
                display: flex;
                justify-content: space-evenly;
                overflow: auto;
                padding: 20px 0;
                .header-dropdown-items {
                    display: flex;
                    flex-direction: column;
                    span {
                        border-bottom: 1px solid #ccc;
                        padding: 5px 0;
                        cursor: pointer;
                    }
                    span:nth-last-child(1) {
                        border-bottom: unset;
                        padding-bottom: 20px;
                    }
                    
                }
            }
        }
        
        .user-account {
           display: flex;
           align-items: center;
           @media(max-width: 992px) {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                margin-bottom: 20px;
            }
        }
        @media(max-width: 992px) {
            flex-direction: column-reverse;
        }
    }  
}